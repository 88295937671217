import React from 'react';
import Layout from '../../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PageHeading from '../../components/PageHeading/PageHeading';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import ReactLivestream from 'react-livestream';

const OfflineComponent = () => {
  return (
    <Container>
      <h2>Offline</h2>
      <br />
      <p>
        Tune in on the first and third Monday of each month at 9pm Eastern Time!
      </p>
      <p>
        If we are live and you do not see the video, please refresh the page.
      </p>
    </Container>
  );
};

const LiveBroadcast = () => {
  return (
    <Layout>
      <Helmet
        title="VEYM | Live"
        meta={[
          {
            name: 'description',
            content: 'Live broadcast',
          },
        ]}
      />
      <PageHeading>Live Broadcast</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Link to="/news">News</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Live</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <br />
      <Container>
        <ReactLivestream
          platform="youtube"
          youtubeApiKey="AIzaSyA97Oqlc3JqnAFB9pV4PT-roR9O4q8iXII"
          // veym youtube channel ID: UCj_Oh1W16LdOf0h6m9kUd1Q
          youtubeChannelId="UCj_Oh1W16LdOf0h6m9kUd1Q"
          //  use the following channel ID (it is always live) to test
          //   youtubeChannelId="UCjzHeG1KWoonmf9d5KBvSiw"
          offlineComponent={<OfflineComponent />}
        />
      </Container>
    </Layout>
  );
};

export default LiveBroadcast;
